import logo from './logo.svg';
import './App.css';
import mysteryBox from './mystery-box-photo.png';

function App() {
    return (
        <div className="App">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <a className="navbar-brand" href="#">amazun.com</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Shop</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">About us</a>
                        </li>
                    </ul>
                </div>
            </nav>
            <div className="flex-center">
                <div className="card shadow-lg p-3 mb-5 bg-white rounded" style={{width: "18rem"}}>
                    <img src={mysteryBox} className="card-img-top mystery-box" alt="..."></img>
                    <div className="card-body">
                        <h5 className="card-title">Some amazing item</h5>
                        <p className="card-text">An item you would really like to buy and you need to get the info about its availability asap</p>
                        <a href="#" className="btn btn-primary">Out of stock</a>
                        <div className="notify">Let me know when available</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
